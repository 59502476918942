<template>
  <div class="table-wrapper">
    <table class="table">
      <tr>
        <th v-for="(item, index) in columns" :key="index">
          {{ item.title }}
        </th>
      </tr>
      <tr
        v-for="(item, index) in list"
        :key="index"
        @click="$emit('clickRow', item)"
      >
        <td v-for="(column, idx) in columns" :key="idx">
          {{ item[column.key] }}
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
export default {
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="less" scoped>
.table-wrapper {
}

.table {
  width: 100%;
  th {
    background-color: #6f83ef;
    padding: 4px 16px;

    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
  }
  td {
    cursor: pointer;
    padding: 7px 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>