// import { fetchFacilityList, fetchFacilityStat, fetchFacilityDetail } from "@/api/model";
import { wgs84togcj02 } from "@/utils/address";
import { fetchList } from "@/api/model";

export default {

    namespaced: true,

    state() {
        return {

            clearMap: false, // 每次要清空地图就取反

            rainList: [], // 降雨量的点
            rainFall: {}, // 上面所有点的某时间范围内的降雨量详情
            activeRainId: null, // set value when click the marker
            rainVisible: false, // open modal

            // 预报页面的水位点
            waterList: [],
            water: {},
            activeWaterId: null,
            waterVisible: false,

            // 预警页面的水位点
            warningList: [],

            waterQualityList: [], // 水质列表，预警和预演页面都需要

            speedList: [], // 预警页面流速列表
            rehearsalWaterList: [], // 预警页面水位 marker
            flowList: [], // 预警页面流量列表
            rehearsalWaterQualityList: [], // 预警页面水质列表

            activeRehearsalWaterId: null, // 预警页面水位点 id
            rehearsalWaterVisible: false,

            flowId: null, // 预警页面流量点 id
            flowVisible: false,

            waterGateList: [], // 水闸列表

        }
    },

    getters: {
        // 大暴雨数量
        rainObj(state) {
            let bigcount = 0;
            let smallCount = 0;
            state.rainList.forEach(item => {
                if (item.value >= 100) {
                    bigcount++
                } else {
                    smallCount++
                }
            })
            return {
                bigcount,
                smallCount,
            }
        },
        // 详情转换成可渲染的格式
        rainFallList(state) {
            const keys = Object.keys(state.rainFall);
            return keys
                .map((key) => {
                    return {
                        name: key,
                        list: state.rainFall[key],
                    };
                });
        },
        // 渲染最初的三个
        renderRainFallList(state, gatters) {
            return gatters.rainFallList.filter((key, index) => index < 3)
        },


        // 左侧表格，所有站点的水位平均值
        filteredWaterList(state) {
            if (typeof state.water === 'object') {
                const keys = Object.keys(state.water)
                const list = keys
                    .map((key) => {
                        return {
                            id: key,
                            list: state.water[key],
                        };
                    });

                console.log('list', list)

                return state.waterList.map(item => {
                    const obj = list.find(element => element.id === item.id) || {}
                    let sum = 0;
                    if (obj && Array.isArray(obj.list)) {

                        obj.list.forEach(val => {
                            sum += val.count;
                        })
                        sum = (sum / obj.list.length).toFixed(2);
                    }
                    return {
                        ...item,
                        ...obj,
                        sum
                    }
                })
            } else {
                return []
            }
        }

    },

    mutations: {

        setClearMap(state) {
            console.log("取反清空地图")
            state.clearMap = !state.clearMap;
        },

        setRainList(state, payload) {
            state.rainList = payload;
        },
        setRainFall(state, payload) {
            state.rainFall = payload;
        },
        setActiveRainId(state, payload) {
            state.activeRainId = payload;
        },
        setRainVisible(state, payload) {
            state.rainVisible = payload;
        },

        setWaterList(state, payload) {
            state.waterList = payload;
        },
        setWater(state, payload) {
            state.water = payload;
        },
        setActiveWaterId(state, payload) {
            state.activeWaterId = payload;
        },
        setWaterVisible(state, payload) {
            state.waterVisible = payload;
        },

        setWarningList(state, payload) {
            state.warningList = payload;
        },
        setWaterQualityList(state, payload) {
            state.waterQualityList = payload;
        },


        setSpeedList(state, payload) {
            state.speedList = payload;
        },
        setRehearsalWaterList(state, payload) {
            state.rehearsalWaterList = payload;
        },
        setFlowList(state, payload) {
            state.flowList = payload;
        },

        setActiveRehearsalWaterId(state, payload) {
            state.activeRehearsalWaterId = payload;
        },
        setRehearsalWaterVisible(state, payload) {
            state.rehearsalWaterVisible = payload;
        },
        setFlowId(state, payload) {
            state.flowId = payload;
        },
        setFlowVisible(state, payload) {
            state.flowVisible = payload;
        },


        setWaterGateList(state, payload) {
            state.waterGateList = payload
        },
    },

    actions: {

        // 获取所有的雨情站点，再获取他们的降雨量，然后合并数据，根据不同的降雨量渲染不同颜色的marker
        getRainList({ commit }, payload) {
            console.log(commit, payload)

            // fetchFacilityList({
            //     type: "rain_gauge",
            // }).then((res) => {
            //     if (Array.isArray(res)) {

            //         // 获取统计的降雨量
            //         fetchFacilityStat({
            //             "ids": res.map(item => item.id),
            //             "type": "rainfall",
            //             ...payload,
            //         }).then(result => {
            //             if (typeof result === 'object') {

            //                 const list = res.map(item => {
            //                     let value = 0;

            //                     const stationDetail = result[item.id];
            //                     if (typeof stationDetail === 'object') {
            //                         const keys = Object.keys(stationDetail);
            //                         keys.forEach(key => {
            //                             value += stationDetail[key]
            //                         })
            //                         if (value > 0) {
            //                             value = Number((value / keys.length).toFixed(2));
            //                         }
            //                     }

            //                     return {
            //                         ...item,
            //                         ...wgs84togcj02(item.x, item.y),
            //                         value,
            //                     }
            //                 })

            //                 commit('setRainList', list)

            //             }
            //         })


            //         // 获取详细的降雨量，渲染成图表
            //         fetchFacilityDetail({
            //             "ids": res.map(item => item.id),
            //             ...payload,
            //             "type": "rainfall"
            //         }).then(res => {
            //             commit('setRainFall', Object.freeze(res))
            //         })


            //     }
            // });

        },

        getWaterList({ commit }, payload) {
            console.log(commit, payload)

            // fetchFacilityList({
            //     type: "liquid_level",
            // }).then((res) => {
            //     if (Array.isArray(res)) {

            //         commit('setWaterList', res.map(item => {

            //             return {
            //                 ...item,
            //                 ...wgs84togcj02(item.x, item.y),
            //             }
            //         }))

            //         // 获取详细的降雨量，渲染成图表
            //         fetchFacilityDetail({
            //             "ids": res.map(item => item.id),
            //             ...payload,
            //             "type": "liquid_level"
            //         }).then(res => {
            //             commit('setWater', Object.freeze(res))
            //         })


            //     }
            // });

        },

        // 预警页面获取水位点，渲染成红色 marker
        getWarningList({ commit }) {

            console.log(commit)

            // fetchFacilityList({
            //     type: "liquid_level",
            // }).then((res) => {
            //     if (Array.isArray(res)) {
            //         commit('setWarningList', Object.freeze(res.map(item => {
            //             return {
            //                 ...item,
            //                 ...wgs84togcj02(item.x, item.y),
            //             }
            //         })))
            //     }
            // });

        },
        // 预警页面获取水质数据，只显示部分水质
        getWarningWaterQualityList({ commit }, payload) {
            fetchList({
                ...payload,
            }).then((res) => {
                if (Array.isArray(res)) {
                    const list = [];

                    res.forEach((item) => {
                        if (Array.isArray(item.nodes)) {
                            let num = Math.abs(item.count);

                            let color = "#f00";

                            // 总磷
                            if (num <= 0.02) {
                                color = "#0033FF"; // 蓝色
                            } else if (num <= 0.1) {
                                color = "#0066FF"; // 浅蓝色
                            } else if (num <= 0.2) {
                                color = "#3399FF"; // 绿色
                            } else if (num <= 0.3) {
                                color = "#66CCFF"; // 黄色
                            } else if (num <= 0.4) {
                                color = "#F08080"; // 粉色
                            }

                            const nodes = item.nodes
                                .filter((node) => {
                                    return node && String(node.lng).indexOf(".") > 0;
                                })
                                .map((node) => {
                                    const obj = wgs84togcj02(node.lng, node.lat);
                                    if (obj.lng && obj.lat) {
                                        return obj;
                                    } else {
                                        console.log("非法转换", node, obj);
                                        return {
                                            lat: 0,
                                            lng: 0,
                                        };
                                    }
                                });

                            if (color === '#f00') {

                                list.push({
                                    nodes,
                                    color,
                                    num,
                                });
                            }

                        }
                    });

                    if (list.length > 0) {
                        commit("setWaterQualityList", list)
                    }
                }
            });
        },


        getRehearsalList({ commit }, payload) {
            fetchList({
                ...payload,
            }).then((res) => {
                if (Array.isArray(res)) {
                    const list = [];

                    res.forEach((item) => {
                        if (item.us && Array.isArray(item.us.nodes)) {
                            let color = "#f00";
                            let num = Math.abs(item.count);
                            if (num < 0.05) {
                                color = "#f00";
                            } else if (num >= 0.05 && num < 0.1) {
                                color = "#080";
                            } else if (num >= 0.1 && num < 0.2) {
                                color = "#87CEFA";
                            } else if (num >= 0.2) {
                                color = "#00f";
                            }

                            list.push({
                                nodes: item.us.nodes.map((element) => ({
                                    id: item.us.id,
                                    ...wgs84togcj02(element.lng, element.lat),
                                })),
                                color,
                            });
                        } else if (item.lng) {
                            list.push({
                                id: item.id,
                                ...wgs84togcj02(item.lng, item.lat),
                                count:
                                    typeof item.count === "number" ? item.count.toFixed(2) : 0,
                            });
                        } else if (
                            item.us &&
                            item.us.id &&
                            !item.us.nodes &&
                            typeof item.count === "number"
                        ) {
                            list.push({
                                id: item.us.id,
                                count: item.count,
                            });
                        } else if (Array.isArray(item.nodes)) {
                            let num = Math.abs(item.count);

                            let color = "#f00";

                            if (payload.type === "hydro_rr_tph") {
                                // 总磷
                                if (num <= 0.02) {
                                    color = "#0033FF"; // 蓝色
                                } else if (num <= 0.1) {
                                    color = "#0066FF"; // 浅蓝色
                                } else if (num <= 0.2) {
                                    color = "#3399FF"; // 绿色
                                } else if (num <= 0.3) {
                                    color = "#66CCFF"; // 黄色
                                } else if (num <= 0.4) {
                                    color = "#F08080"; // 粉色
                                }
                            } else if (payload.type === "hydro_rr_nh4") {
                                // 氨氮
                                if (num <= 0.15) {
                                    color = "#0033FF"; // 蓝色
                                } else if (num <= 0.5) {
                                    color = "#0066FF"; // 浅蓝色
                                } else if (num <= 1.0) {
                                    color = "#3399FF"; // 绿色
                                } else if (num <= 1.5) {
                                    color = "#66CCFF"; // 黄色
                                } else if (num <= 2.0) {
                                    color = "#F08080"; // 粉色
                                }
                            } else if (payload.type === "hydro_rr_cod") {
                                // 化学需氧量
                                if (num <= 10) {
                                    color = "#0033FF"; // 蓝色
                                } else if (num <= 15) {
                                    color = "#0066FF"; // 浅蓝色
                                } else if (num <= 20) {
                                    color = "#3399FF"; // 绿色
                                } else if (num <= 30) {
                                    color = "#66CCFF"; // 黄色
                                } else if (num <= 40) {
                                    color = "#F08080"; // 粉色
                                }
                            }

                            const nodes = item.nodes
                                .filter((node) => {
                                    return node && String(node.lng).indexOf(".") > 0;
                                })
                                .map((node) => {
                                    const obj = wgs84togcj02(node.lng, node.lat);
                                    if (obj.lng && obj.lat) {
                                        return obj;
                                    } else {
                                        console.log("非法转换", node, obj);
                                        return {
                                            lat: 0,
                                            lng: 0,
                                        };
                                    }
                                });

                            list.push({
                                nodes,
                                color,
                                num,
                            });
                        }
                    });

                    console.log("fetch list", list);

                    if (list.length > 0) {
                        if (payload.type === "us_vel") {
                            console.log('流速')
                            // this.addLine({ list });
                            commit("setSpeedList", Object.freeze(list))
                        } else if (payload.type === "node_depnod") {
                            console.log('水位')
                            // this.addMarker(list);
                            commit("setRehearsalWaterList", Object.freeze(list))

                        } else if (payload.type === "hydro_ds_flow") {
                            // this.addLabel(list);
                            console.log('流量')
                            commit('setFlowList', Object.freeze(list))
                        } else if (
                            payload.type === "hydro_rr_tph" ||
                            payload.type === "hydro_rr_nh4" ||
                            payload.type === "hydro_rr_cod"
                        ) {
                            commit("setWaterQualityList", list)
                            // this.addLine({
                            //     list,
                            //     showDir: false,
                            //     roundLine: false,
                            //     clearMap: true,
                            // });
                        }
                    }
                }
            });
        },


        getWaterGateList({ commit }) {
            console.log(commit)
            // fetchFacilityList({
            //     type: "sluice",
            // }).then((res) => {
            //     if (Array.isArray(res)) {
            //         commit('setWaterGateList', res)
            //     }
            // });

        }
    }

}