import Vue from 'vue'
import App from './App.vue'
import './index.css'
import store from './store'

import { Icon } from 'element-ui'
import { Radio, ConfigProvider, Modal, Checkbox, Select, Slider, Button, Row, Col } from 'ant-design-vue'

import AModal from './components/modal'
import Title from './components/title'
import Table from './components/table'

Vue.use(Icon)
Vue.use(Radio).use(Modal).use(ConfigProvider).use(Checkbox).use(Slider).use(Button).use(Row).use(Col).use(Select)

Vue.component('Modal', AModal)
Vue.component('Title', Title)
Vue.component('Table', Table)

Vue.config.productionTip = false

new Vue({
  store,
  render: h => h(App)
}).$mount('#app')
