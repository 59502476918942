<template>
  <div class="modal" :style="{ width }" v-if="visible">
    <div class="header">
      <span>{{ title }}</span>
      <img
        src="@/assets/close.png"
        class="close"
        alt=""
        @click="$emit('close')"
      />
    </div>
    <div class="info">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "70vw",
    },
  },
};
</script>

<style lang="less" scoped>
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 99;
  transform: translateX(-50%) translateY(-50%);

  .header {
    background: linear-gradient(to bottom, #6f83ef, #9daef1);
    height: 4.5vh;
    padding: 0 1vw;
    font-size: 2vh;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    .close {
      height: 1.8vh;
      cursor: pointer;
    }
  }

  .info {
    background-color: #fff;
    padding: 1vw;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}
</style>