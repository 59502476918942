<template>
  <span @click="visible = true" class="left date">
    <i class="el-icon-date"></i>
    <span style="margin-left: 8px">
      {{ dateList[activeIndex].date }}
    </span>

    <a-modal
      :visible="visible"
      title="选择日期"
      :footer="null"
      @cancel="cancel"
      width="400px"
    >
      <a-row :gutter="[8, 8]">
        <a-col :span="8" v-for="(item, index) in dateList" :key="index">
          <a-button
            :type="activeIndex === index ? 'primary' : 'default'"
            @click="onClick(index)"
          >
            {{ item.date }}
          </a-button>
        </a-col>
      </a-row>
      <a-button-group> </a-button-group>
    </a-modal>
  </span>
</template>

<script>
export default {
  props: {
    dateList: {
      type: Array,
      default: () => [],
    },
    activeIndex: {
      type: Number,
    },
  },

  data() {
    return {
      visible: false,
    };
  },
  methods: {
    onClick(index) {
      this.$emit("setActiveIndex", index);
      this.visible = false;
    },
    cancel() {
      this.visible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.date {
  cursor: pointer;
  width: 150px;
  margin-left: 24px;
}
</style>