<template>
  <div style="background: #fff">
    <div :id="id" :style="{ width: '100%', height }"></div>
  </div>
</template>

<script>
import * as echarts from "echarts/core";
import { TooltipComponent, GridComponent } from "echarts/components";
import { BarChart, LineChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([
  TooltipComponent,
  GridComponent,
  BarChart,
  LineChart,
  CanvasRenderer,
]);

export default {
  props: {
    id: {
      type: String,
    },
    list: {
      type: Array,
      default: () => [],
    },
    height: {
      type: String,
      default: "50vh",
    },
  },

  mounted() {
    var chartDom = document.getElementById(this.id);
    var myChart = echarts.init(chartDom);
    var option;

    let cumulationList = [];
    this.list.forEach((item) => {
      if (cumulationList.length === 0) {
        cumulationList.push(item.count);
      } else {
        cumulationList.push(
          item.count + cumulationList[cumulationList.length - 1]
        );
      }
    });

    const color = [
      {
        type: "linear",
        x: 0,
        y: 0,
        x2: 0,
        y2: 1,
        colorStops: [
          {
            offset: 0,
            color: "#258E36",
          },
          {
            offset: 1,
            color: "#fff",
          },
        ],
      },
      {
        type: "linear",
        x: 0,
        y: 0,
        x2: 0,
        y2: 1,
        colorStops: [
          {
            offset: 0,
            color: "#6F83EF", // 0% 处的颜色
          },
          {
            offset: 1,
            color: "#fff", // 100% 处的颜色
          },
        ],
      },
    ];
    option = {
      color,
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
        },
      },

      grid: {
        top: 55,
        right: 40,
        bottom: 55,
        left: 40,
      },

      xAxis: [
        {
          type: "category",
          boundaryGap: false,
          data: this.list.map((item) => item.ts),
        },
        {
          type: "category",
          position: "top",
          data: this.list.map((item) => item.ts),
        },
      ],
      yAxis: [
        {
          type: "value",
          name: "模拟水位(mm)",
          position: "right",
          nameTextStyle: {
            padding: [0, 40, 15, 0],
          },
          axisLine: {
            show: true,
          },
        },

        {
          type: "value",
          name: "预报雨量(mm)",
          inverse: true,
          nameTextStyle: {
            padding: [15, 0, 0, 40],
          },
          axisLine: {
            show: true,
          },
        },
      ],
      series: [
        {
          name: "累计降雨量",
          type: "line",
          smooth: true,
          areaStyle: {},
          data: cumulationList,
        },

        {
          name: "小时降雨量",
          type: "bar",
          xAxisIndex: 1,
          yAxisIndex: 1,
          data: this.list.map((item) => item.count),
        },
      ],
    };

    option && myChart.setOption(option);
  },
};
</script>