<template>
  <div>
    <div id="map"></div>
    <Rehearsal />
  </div>
</template>
<script>
import moment from "moment";
import { mapMutations, mapState } from "vuex";

import marker1 from "@/assets/marker1.png";
import marker2 from "@/assets/marker2.png";
import marker3 from "@/assets/marker3.png";
import marker4 from "@/assets/marker4.png";
import marker5 from "@/assets/marker5.png";
import marker6 from "@/assets/marker6.png";

import markerWater from "@/assets/marker-water.png";
import markerWarning from "@/assets/marker-warning.png";

import markerImage from "@/assets/marker10.png";

import markerWaterGateActive from "@/assets/gate-active.png";

import Rehearsal from "../views/rehearsal/index.vue";

import { icon1, icon2, icon3, icon4 } from "./icon";

import { fetchDeviceList, fetchDeviceDetail } from "@/api/model";

export default {
  components: {
    Rehearsal,
  },

  computed: {
    ...mapState("map", [
      "clearMap",
      "rainList",
      "waterList",
      "warningList",
      "waterQualityList",

      "speedList",
      "rehearsalWaterList",
      "flowList",

      "waterGateList",
    ]),
  },

  watch: {
    clearMap(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (this.map) {
          this.map.clearMap();

          this.setDevice();
        }
      }
    },

    rainList(newValue) {
      if (newValue) {
        console.log("rain list changed");
        this.addMarker({ type: "rain", list: newValue });
      }
    },

    waterList(newValue) {
      if (newValue) {
        console.log("water list changed");
        this.addMarker({ type: "water", list: newValue });
      }
    },

    warningList(newValue) {
      if (newValue) {
        console.log("预警页面的水位数据更新");
        this.addMarker({ type: "warning", list: newValue });
      }
    },

    waterQualityList(newValue) {
      if (newValue) {
        console.log("预警页面的水质数据更新");
        this.addLine({ list: newValue });
      }
    },

    speedList(newValue) {
      if (newValue) {
        console.log("预演页面流速更新");
        this.addLine({ list: newValue });
      }
    },

    rehearsalWaterList(newValue) {
      if (newValue) {
        console.log("预演页面的水位列表更新");
        this.addRehearsalMarker({
          type: "rehearsal-water",
          list: newValue,
        });
      }
    },

    flowList(newValue) {
      if (newValue) {
        console.log("预演页面的流量数据更新");
        this.addLabel(newValue);
      }
    },

    waterGateList(newValue) {
      if (newValue) {
        console.log("闸泵数据更新");
        this.addMarker({ type: "waterGate", list: newValue, remove: false });
      }
    },
  },

  mounted() {
    this.initMap();
    this.$once("hook:beforeDestroy", () => {
      this.map?.destroy();
    });

    this.setDevice();
  },

  methods: {
    initMap() {
      const { AMap } = window;
      var map = new AMap.Map("map", {
        center: [118.81979358738899, 32.04820166999079],
        zoom: 16,
        viewMode: "3D",
        pitch: 45,
        mapStyle: "amap://styles/cb7f7242897a033c6c2beea82bcc7d94", //设置地图的显示样式
      });

      this.map = map;
    },

    setDevice() {
      fetchDeviceList({
        pageNum: 1,
        pageSize: 9999,
      }).then((res) => {
        console.log("device list", res);
        if (res && Array.isArray(res.list)) {
          const that = this;

          const markers = res.list.map((item) => {
            let iconPath = icon1; // waterquality 水质
            if (item.type === "flowMeter") {
              iconPath = icon2;
            } else if (item.type === "rainGauge") {
              iconPath = icon3;
            } else if (item.type === "liquidLevel") {
              iconPath = icon4;
            }
            const { AMap } = window;
            if (!AMap) return;

            var icon = new AMap.Icon({
              size: new AMap.Size(27, 30), // 图标尺寸
              image: iconPath, // Icon的图像
              imageOffset: new AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
              imageSize: new AMap.Size(27, 30), // 根据所设置的大小拉伸或压缩图片
            });

            const marker = new AMap.Marker({
              icon,
              position: new AMap.LngLat(item.x, item.y),
              offset: new AMap.Pixel(40, -20), // 相对于基点的偏移位置
              extData: {
                ...item,
              },
            });

            marker.on("click", (e) => {
              console.log("e", e);
              const { lng, lat } = e.lnglat;
              const { extData } = e.target._opts;

              function openModal(res = {}) {
                let content = `

                `;

                if (extData.type === "waterquality") {
                  content = `
                    <div class='between'>
                      <span>氨氮(mg/l) ：</span>
                      <span>${0.31}</span>
                    </div>
                    <div class='between'>
                      <span>cod(mg/l)：</span>
                      <span>${5.47}</span>
                    </div>
                    <div class='between'>
                      <span>ph(mg/l)：</span>
                      <span>${7.96}</span>
                    </div>
                  `;
                } else if (extData.type === "flowMeter") {
                  content = `
                    <div class='between'>
                      <span>水位(米)：</span>
                      <span>${res.water_level}</span>
                    </div>
                    <div class='between'>
                      <span>流速(米/秒)：</span>
                      <span>${res.speed_flow}</span>
                    </div>
                    <div class='between'>
                      <span>温度(摄氏度)：</span>
                      <span>${res.temp}</span>
                    </div>
                  `;
                } else if (extData.type === "rainGauge") {
                  content = `
                    <div class='between'>
                      <span>上报周期内雨量(mm)：</span>
                      <span>${res.interrainfall}</span>
                    </div>
                  `;
                } else if (extData.type === "liquidLevel") {
                  content = `
                    <div class='between'>
                      <span>水深(mm)：</span>
                      <span>${res.objlength}</span>
                    </div>
                    <div class='between'>
                      <span>传感器至水面的距离(mm)：</span>
                      <span>${res.distance}</span>
                    </div>
                    <div class='between'>
                      <span>传感器至水底的距离(mm)：</span>
                      <span>${res.totallenght}</span>
                    </div>
                  `;
                }

                var infoWindow = new AMap.InfoWindow({
                  isCustom: true,
                  content: `
                  <div style="
                    background: #67927f;
                    color: #fff;
                    box-shadow: 0 0 16px rgba(0,0,0, 0.2);
                    padding: 12px;
                    font-size: 12px;
                    border-radius: 8px;
                    position: relative;
                  ">
                    <div style="font-weight: bold;font-size: 16px;margin-bottom: 4px">
                        ${extData.name}
                    </div>
                    ${content}
                    <div class='between'>
                      <span>位置：</span>
                      <span>${extData.location}</span>
                    </div>
                    <div class='between' >
                      <span>通讯时间：</span>
                      <span>${
                        res.collecttime
                          ? res.collecttime
                          : moment().format("YYYY-MM-DD HH:mm:ss")
                      }</span>
                    </div>
                    <div style="
                    position:absolute;
                    bottom: -10px;
                    left: 50%;
    transform: translateX(-50%);
                    
                                  height: 0;
            width: 0;
            border-top: 10px solid #67927f;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 0px solid transparent;
                    "></div>
                  </div>
               `,
                  offset: new AMap.Pixel(-7.5, -19),
                });

                infoWindow.open(that.map, [lng, lat]);
              }

              if (extData.type === "waterquality") {
                openModal();
              } else {
                fetchDeviceDetail([extData.sn]).then((res) => {
                  console.log("device detail", res);

                  if (!Array.isArray(res)) {
                    alert("查无数据");
                    return;
                  }

                  openModal(res[0]);
                });
              }
            });

            return marker;
          });

          // this.markers = markers;
          this.map.add(markers);
        }
      });
    },

    addMarker({ type = "", list = [], remove = true }) {
      if (!this.map) {
        return;
      }
      const { AMap } = window;

      if (remove) {
        this.removeMarkerClickEvent(this.markers);
        if (Array.isArray(this.markers) && this.markers.length > 0) {
          this.map.remove(this.markers);
        }
      }

      let handledList = list;
      if (type === "rain") {
        // 降雨量
        handledList = list.map((item) => {
          let image;
          if (item.value < 10) {
            image = marker1;
          } else if (item.value < 25) {
            image = marker2;
          } else if (item.value < 50) {
            image = marker3;
          } else if (item.value < 100) {
            image = marker4;
          } else if (item.value < 250) {
            image = marker5;
          } else if (item.value >= 250) {
            image = marker6;
          }
          return {
            ...item,
            icon: new AMap.Icon({
              size: new AMap.Size(40, 40), // 图标尺寸
              image, // Icon的图像
              // imageOffset: new AMap.Pixel(-20, -20), // 图像相对展示区域的偏移量，适于雪碧图等
              imageSize: new AMap.Size(40, 40), // 根据所设置的大小拉伸或压缩图片
            }),
          };
        });
      }

      if (type === "water") {
        handledList = list.map((item) => {
          let image = markerWater;

          return {
            ...item,
            icon: new AMap.Icon({
              size: new AMap.Size(40, 40), // 图标尺寸
              image, // Icon的图像
              // imageOffset: new AMap.Pixel(-20, -20), // 图像相对展示区域的偏移量，适于雪碧图等
              imageSize: new AMap.Size(40, 40), // 根据所设置的大小拉伸或压缩图片
            }),
          };
        });
      }

      if (type === "warning") {
        handledList = list.map((item) => {
          let image = markerWarning;
          return {
            ...item,
            icon: new AMap.Icon({
              size: new AMap.Size(40, 40), // 图标尺寸
              image, // Icon的图像
              // imageOffset: new AMap.Pixel(-20, -20), // 图像相对展示区域的偏移量，适于雪碧图等
              imageSize: new AMap.Size(40, 40), // 根据所设置的大小拉伸或压缩图片
            }),
          };
        });
      }

      if (type === "waterGate") {
        handledList = list.map((item) => {
          let image = markerWaterGateActive;
          return {
            ...item,
            icon: new AMap.Icon({
              size: new AMap.Size(40, 47), // 图标尺寸
              image, // Icon的图像
              // imageOffset: new AMap.Pixel(-20, -20), // 图像相对展示区域的偏移量，适于雪碧图等
              imageSize: new AMap.Size(40, 47), // 根据所设置的大小拉伸或压缩图片
            }),
          };
        });
      }

      const markers = handledList.map((item) => {
        const marker = new AMap.Marker({
          icon: item.icon,
          position: new AMap.LngLat(item.x, item.y),
          // offset: new AMap.Pixel(-35, -20), // 相对于基点的偏移位置
          offset: new AMap.Pixel(-20, -20), // 相对于基点的偏移位置
          extData: {
            id: item.id,
            type,
          },
        });

        marker.on("click", this.onMarkerClick);

        return marker;
      });

      this.markers = markers;
      this.map.add(markers);
    },

    addRehearsalMarker({ type = "", list = [] }) {
      if (!this.map) {
        return;
      }
      const { AMap } = window;

      this.removeMarkerClickEvent(this.markers);
      if (Array.isArray(this.markers) && this.markers.length > 0) {
        this.map.remove(this.markers);
      }

      const markers = list.map((item) => {
        const marker = new AMap.Marker({
          content: `<div style="
                background-image: url(${markerImage});
    background-repeat: no-repeat;
  background-size: cover;
  font-size: 12px;
  height: 43px;
  width: 68px;
  line-height: 24px;
  color: #fff;
  text-align: right;
">  
    <div style="
      position: absolute;
      top: 2px;
      right: 14px;
    ">
            ${item.count}
    </div>
          </div>`,
          position: new AMap.LngLat(item.lng, item.lat),
          offset: new AMap.Pixel(-65, -40), // 相对于基点的偏移位置
          extData: {
            id: item.id,
            type,
          },
        });

        marker.on("click", this.onMarkerClick);

        return marker;
      });

      this.markers = markers;
      this.map.add(markers);
    },

    addLine({ list = [], showDir = true, roundLine = true }) {
      if (!this.map) {
        return;
      }

      const { AMap } = window;

      list.forEach((item) => {
        if (Array.isArray(item.nodes)) {
          var path = item.nodes.map((element) => {
            return new AMap.LngLat(element.lng, element.lat);
          });

          // 创建折线实例
          var polyline = new AMap.Polyline({
            path: path,
            strokeWeight: 8, // 线条宽度，默认为 1
            strokeColor: item.color, // 线条颜色
            lineJoin: roundLine ? "round" : "miter", // 折线拐点连接处样式
            showDir,
          });

          // 将折线添加至地图实例
          this.map.add(polyline);
        }
      });
    },

    addLabel(arr) {
      console.log("arr", arr);
      console.log("list", this.speedList);

      const result = [];
      for (let i = 0; i < arr.length; i++) {
        const id = arr[i].id;
        for (let j = 0; j < this.speedList.length; j++) {
          const item = this.speedList[j];
          if (Array.isArray(item.nodes) && item.nodes.length > 0) {
            console.log("speed list has item", item);

            if (item.nodes[0].id === id) {
              console.log("find you", item);

              let idx = 0;
              if (item.nodes.length > 1) {
                idx = Math.round(item.nodes.length / 2);
                if (idx > 0) {
                  idx--;
                }
              }

              result.push({
                ...item.nodes[idx],
                count: Number(arr[i].count).toFixed(2),
              });
            }
          }
        }
      }

      console.log("result", result);

      if (!this.map) {
        return;
      }
      const { AMap } = window;

      this.removeMarkerClickEvent(this.markers);
      if (Array.isArray(this.markers) && this.markers.length > 0) {
        this.map.remove(this.markers);
      }

      const markers = result.map((item) => {
        const marker = new AMap.Text({
          position: new AMap.LngLat(item.lng, item.lat),
          offset: new AMap.Pixel(-20, -15), // 相对于基点的偏移位置
          content: `<div style="
            color: #000;
            font-weight: bold;
          "> ${item.count} </div>`, //设置文本标注内容
          extData: {
            id: item.id,
            type: "flow",
          },
        });

        marker.on("click", this.onMarkerClick);

        // const that = this;

        // marker.on("click", function (e) {
        //   that.flowVisible = true;
        //   console.log("e", e.target._opts.extData.id);
        //   that.flowActiveId = e.target._opts.extData.id;
        // });

        // marker.setLabel({
        //   offset: new AMap.Pixel(20, 20), //设置文本标注偏移量
        //   direction: "right", //设置文本标注方位
        // });

        return marker;
      });

      this.markers = markers;
      this.map.add(markers);
    },

    ...mapMutations("map", [
      "setActiveRainId",
      "setRainVisible",
      "setActiveWaterId",
      "setWaterVisible",

      "setActiveRehearsalWaterId",
      "setRehearsalWaterVisible",
      "setFlowId",
      "setFlowVisible",
    ]),

    onMarkerClick(e) {
      const { id, type } = e.target._opts.extData;
      console.log(id, type);
      // if (type === "rain") {
      //   this.setActiveRainId(id);
      //   this.setRainVisible(true);
      // }

      // if (type === "water") {
      //   this.setActiveWaterId(id);
      //   this.setWaterVisible(true);
      // }

      // if (type === "rehearsal-water") {
      //   this.setActiveRehearsalWaterId(id);
      //   this.setRehearsalWaterVisible(true);
      // }

      // if (type === "flow") {
      //   this.setFlowId(id);
      //   this.setFlowVisible(true);
      // }
    },
    removeMarkerClickEvent(list) {
      if (Array.isArray(list)) {
        list.forEach((item) => {
          item.off("click", this.onMarkerClick);
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
#map {
  position: fixed;
  top: -50px;
  left: 0;
  right: 0;
  bottom: -20px;
  z-index: 10;
}
</style>