//定义一些常量
// var PI = 3.1415926535897932384626;
// var a = 6378245.0;
// var ee = 0.00669342162296594323;

export function wgs84togcj02(lng, lat) {
    // var lat = +lat;
    // var lng = +lng;
    return {
        lng,
        lat,
    }
    // if (out_of_china(lng, lat)) {
    //     return [lng, lat]
    // } else {
    //     var dlat = transformlat(lng - 105.0, lat - 35.0);
    //     var dlng = transformlng(lng - 105.0, lat - 35.0);
    //     var radlat = lat / 180.0 * PI;
    //     var magic = Math.sin(radlat);
    //     magic = 1 - ee * magic * magic;
    //     var sqrtmagic = Math.sqrt(magic);
    //     dlat = (dlat * 180.0) / ((a * (1 - ee)) / (magic * sqrtmagic) * PI);
    //     dlng = (dlng * 180.0) / (a / sqrtmagic * Math.cos(radlat) * PI);
    //     var mglat = lat + dlat;
    //     var mglng = lng + dlng;
    //     return {
    //         lng: mglng,
    //         lat: mglat,
    //     }
    // }
}

// var transformlat = function transformlat(lng, lat) {
//     // var lat = +lat;
//     // var lng = +lng;
//     var ret = -100.0 + 2.0 * lng + 3.0 * lat + 0.2 * lat * lat + 0.1 * lng * lat + 0.2 * Math.sqrt(Math.abs(lng));
//     ret += (20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) * 2.0 / 3.0;
//     ret += (20.0 * Math.sin(lat * PI) + 40.0 * Math.sin(lat / 3.0 * PI)) * 2.0 / 3.0;
//     ret += (160.0 * Math.sin(lat / 12.0 * PI) + 320 * Math.sin(lat * PI / 30.0)) * 2.0 / 3.0;
//     return ret
// }

// var transformlng = function transformlng(lng, lat) {
//     // var lat = +lat;
//     // var lng = +lng;
//     var ret = 300.0 + lng + 2.0 * lat + 0.1 * lng * lng + 0.1 * lng * lat + 0.1 * Math.sqrt(Math.abs(lng));
//     ret += (20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) * 2.0 / 3.0;
//     ret += (20.0 * Math.sin(lng * PI) + 40.0 * Math.sin(lng / 3.0 * PI)) * 2.0 / 3.0;
//     ret += (150.0 * Math.sin(lng / 12.0 * PI) + 300.0 * Math.sin(lng / 30.0 * PI)) * 2.0 / 3.0;
//     return ret
// }


// var out_of_china = function out_of_china(lng, lat) {
//     // var lat = +lat;
//     // var lng = +lng;
//     // 纬度3.86~53.55,经度73.66~135.05 
//     return !(lng > 73.66 && lng < 135.05 && lat > 3.86 && lat < 53.55);
// }
