<template>
  <div id="app">
    <a-config-provider :locale="zh_CN">
      <Layout />
    </a-config-provider>
  </div>
</template>


<script>
import zh_CN from "ant-design-vue/es/locale/zh_CN";
import Layout from "./layout/index.vue";

export default {
  components: {
    Layout,
  },
  data() {
    return {
      zh_CN: Object.freeze(zh_CN),
    };
  },
};
</script>

<style lang='less'>
@import url("//at.alicdn.com/t/font_3037438_476kws7tguf.css");

html {
  font-size: 1vw;
}

body {
  margin: 0;
  font-size: 14px;
  color: #353744;
  font-family: -apple-system, Microsoft YaHei, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.left {
  display: flex;
  align-items: center;
}
.right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.around {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
</style>
