<template>
  <div>
    <div :id="id"></div>
  </div>
</template>

<script>
import * as echarts from "echarts/core";
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  DataZoomComponent,
} from "echarts/components";
import { LineChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  DataZoomComponent,
  LineChart,
  CanvasRenderer,
]);

export default {
  props: {
    id: {
      type: String,
    },
    chartData: {
      type: Array,
      default: () => [],
    },
  },

  watch: {
    chartData(newValue) {
      this.init(newValue);
    },
  },

  mounted() {
    var chartDom = document.getElementById(this.id);
    this.myChart = echarts.init(chartDom, "dark");

    if (this.chartData.length > 0) {
      this.init(this.chartData);
    }
  },

  methods: {
    init(newValue) {
      // 降水量和流量
      if (Array.isArray(newValue)) {
        var option;

        var timeData = newValue.map((item) => item.ts);

        option = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              animation: false,
            },
          },

          axisPointer: {
            link: { xAxisIndex: "all" },
          },
          dataZoom: [
            {
              show: true,
              realtime: true,
              start: 30,
              end: 70,
              xAxisIndex: [0, 1],
            },
            {
              type: "inside",
              realtime: true,
              start: 30,
              end: 70,
              xAxisIndex: [0, 1],
            },
          ],

          xAxis: [
            {
              boundaryGap: false,
              axisLine: { onZero: true },
              data: timeData,
            },
          ],
          yAxis: [
            {
              name: "液位高程(m)",
              type: "value",
            },
          ],
          series: [
            {
              name: "液位高程",
              type: "line",
              data: newValue.map((item) => {
                return item.count;
              }),
            },
          ],
        };

        option && this.myChart.setOption(option);
      }
    },
  },
};
</script>

<style scoped>
#chart1 {
  height: 400px;
  width: 100%;
}
</style>