import axios from 'axios'
import { message } from 'ant-design-vue'

// axios.defaults.baseURL = 'http://10.23.21.37/api'
axios.defaults.baseURL = 'http://pipe.njszy.com/api/model-analysis'

if (process.env.NODE_ENV === 'production') {
    axios.defaults.baseURL = '/api'
}

const instance = axios.create({
})

instance.interceptors.request.use(function (config) {

    config.headers = {
        user_uuid: window.localStorage.getItem('userId'),
    }

    return config;
}, function (error) {
    return Promise.reject(error)
})

instance.interceptors.response.use(function (response) {

    const { data = {} } = response;

    if (response.status === 201) {
        message.success("操作成功")
        return data;
    } else if (response.status === 200) {
        return data.data
    } else {
        return data;
    }

}, function (error) {

    console.log('error', error)

    const { status, data } = error.response;
    console.log('status: ', status)

    message.error(data.message)

    return Promise.reject(error)
})

export default instance;