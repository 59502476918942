import request from '../request'

export function fetchCategory() {
    return request({
        url: '/model-analysis/hydro/yudaihe/category/list',
    })

}

export function fetchList(data) {
    return request({
        url: '/model-analysis/hydro/yudaihe/query',
        method: 'post',
        data
    })
}

export function fetchDetail(data) {
    return request({
        url: '/model-analysis/hydro/stat',
        method: 'post',
        data
    })
}

export function fetchTimeList() {
    return request({
        url: '/model-analysis/hydro/yudaihe/timerange',
    })
}

// 获取设备（包括水闸等）列表
export function fetchFacilityList(data) {
    return request({
        url: '/model-analysis/facility/list',
        method: 'post',
        data
    })
}


export function fetchFacilityStat(data) {
    return request({
        url: '/model-analysis/facility/stat',
        method: 'post',
        data,
    })
}


// 获取设备的业务数据详情
export function fetchFacilityDetail(data) {
    return request({
        url: '/model-analysis/facility/query',
        method: 'post',
        data,
    })
}


export function fetchDeviceList(params) {
    return request({
        url: '/model-analysis/hydro/yudaihe/device/list',
        params,
    })
}

export function fetchDeviceDetail(data) {
    return request({
        url: '/model-analysis/hydro/yudaihe/realTime',
        method: 'post',
        data,
    })
}