<template>
  <div>
    <div class="panel">
      <div class="header">推演参数</div>
      <div class="body">
        <div class="label" style="padding-top: 12px">工况</div>

        <div class="field">
          <a-select size="small" style="flex: 1" v-model="selectedCategory">
            <a-select-option
              v-for="(item, index) in categorySelectList"
              :key="index"
              :value="item"
            >
              <span :title="item">
                {{ item }}
              </span>
            </a-select-option>
          </a-select>
        </div>

        <div class="label">水环境指标</div>
        <div class="field">
          <div>
            <div>
              <a-radio-group v-model="selectedType">
                <a-radio
                  style="display: block"
                  v-for="(item, index) in typeList"
                  :key="index"
                  :value="item.value"
                >
                  <span>
                    {{ item.label }}
                  </span>
                </a-radio>
              </a-radio-group>
            </div>
            <div>
              <a-radio-group v-model="selectedSubType">
                <a-radio
                  style="display: block"
                  v-for="(item, index) in subTypeList1"
                  :key="index"
                  :value="item.value"
                >
                  <span>
                    {{ item.label }}
                  </span>
                </a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>

        <div class="label">水质</div>
        <div class="field">
          <div>
            <div>
              <a-radio-group v-model="selectedSubType">
                <a-radio
                  :style="{
                    display: 'block',
                  }"
                  v-for="(item, index) in subTypeList2"
                  :key="index"
                  :value="item.value"
                >
                  <span>
                    {{ item.label }}
                  </span>
                </a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>

        <div class="scale">
          <template v-if="selectedType">
            <div
              v-for="(item, index) in colorList.find(
                (item) => item.type === selectedType
              ).list"
              :key="index"
              class="item"
            >
              <div class="color" :style="{ background: item.color }"></div>
              <div class="value">{{ item.value }}</div>
            </div>
          </template>

          <template
            v-if="
              selectedSubType &&
              colorList.find((item) => item.type === selectedSubType)
            "
          >
            <div
              v-for="(item, index) in colorList.find(
                (item) => item.type === selectedSubType
              ).list"
              :key="index"
              class="item"
            >
              <div class="color" :style="{ background: item.color }"></div>
              <div class="value">{{ item.value }}</div>
            </div>
          </template>
        </div>
      </div>
    </div>

    <div class="tool">
      <div class="icon" @click="play">
        <i v-if="isPlay" class="el-icon-video-pause"></i>
        <i v-else class="el-icon-video-play"></i>
      </div>

      <template v-if="dateList.length">
        <DatePicker
          :dateList="dateList"
          :activeIndex="activeDateIndex"
          @setActiveIndex="(idx) => (activeDateIndex = idx)"
        />
        <template v-if="dateList[activeDateIndex].timeList.length > 1">
          <a-slider
            style="width: 100%"
            v-model="activeTimeIndex"
            :tooltip-visible="true"
            :tipFormatter="(idx) => dateList[activeDateIndex].timeList[idx]"
            :max="dateList[activeDateIndex].timeList.length - 1"
          />
        </template>
      </template>
    </div>

    <Modal
      :title="activeRehearsalWaterId"
      :visible="rehearsalWaterVisible"
      @close="setRehearsalWaterVisible(false)"
      width="50vw"
    >
      <WaterChart
        id="active-id"
        :list="[
          { ts: '2022-01-19 12:00:00', count: 10 },
          { ts: '2022-01-19 12:30:00', count: 40 },
          { ts: '2022-01-19 13:00:00', count: 60 },
          { ts: '2022-01-19 13:30:00', count: 40 },
          { ts: '2022-01-19 14:00:00', count: 50 },
          { ts: '2022-01-19 14:30:00', count: 60 },
          { ts: '2022-01-19 15:00:00', count: 100 },
          { ts: '2022-01-19 15:30:00', count: 80 },
          { ts: '2022-01-19 16:00:00', count: 10 },
          { ts: '2022-01-19 16:30:00', count: 40 },
          { ts: '2022-01-19 17:00:00', count: 60 },
          { ts: '2022-01-19 17:30:00', count: 40 },
        ]"
        height="50vh"
      />
    </Modal>

    <Modal
      :title="flowId"
      :visible="flowVisible"
      @close="setFlowVisible(false)"
      width="50vw"
    >
      <FlowChart
        :id="flowId"
        :list="[
          { ts: '2022-01-19 12:00:00', count: 10 },
          { ts: '2022-01-19 12:30:00', count: 40 },
          { ts: '2022-01-19 13:00:00', count: 2 },
          { ts: '2022-01-19 13:30:00', count: 40 },
          { ts: '2022-01-19 14:00:00', count: 6 },
          { ts: '2022-01-19 14:30:00', count: 60 },
          { ts: '2022-01-19 15:00:00', count: 12 },
          { ts: '2022-01-19 15:30:00', count: 3 },
          { ts: '2022-01-19 16:00:00', count: 10 },
          { ts: '2022-01-19 16:30:00', count: 2 },
          { ts: '2022-01-19 17:00:00', count: 60 },
          { ts: '2022-01-19 17:30:00', count: 40 },
        ]"
        height="50vh"
      />
    </Modal>

    <a-modal
      :mask="false"
      title="液位高程"
      :visible="visible"
      :footer="null"
      width="1100px"
      @cancel="cancel"
    >
      <line-chart id="chart1" :chartData="chartData" />
    </a-modal>
  </div>
</template>
<script>
import { fetchCategory, fetchTimeList, fetchDetail } from "@/api/model";
import LineChart from "./components/line-chart.vue";
import DatePicker from "./components/date-picker.vue";
import { debounce } from "lodash";

import { mapActions, mapMutations, mapState } from "vuex";

import WaterChart from "./components/water-chart.vue";
import FlowChart from "./components/flow-chart.vue";

export default {
  components: {
    LineChart,
    DatePicker,

    WaterChart,
    FlowChart,
  },

  data() {
    return {
      list: [], // 流速

      selectedCategory: "",
      categoryList: [],
      categorySelectList: [],
      selectedType: "us_vel",
      typeList: [
        {
          label: "流速",
          value: "us_vel",
        },
      ],

      selectedSubType: "node_depnod",

      subTypeList1: [
        {
          label: "水位",
          value: "node_depnod",
        },
        {
          label: "流量",
          value: "hydro_ds_flow",
        },
      ],
      subTypeList2: [
        {
          label: "总磷",
          value: "hydro_rr_tph",
        },
        {
          label: "氨氮",
          value: "hydro_rr_nh4",
        },
        {
          label: "化学需氧量",
          value: "hydro_rr_cod",
        },
      ],

      // 液位高程折线图
      visible: false, // click marker
      activeId: null, // selected marker id
      chartData: [],

      // 流量折线图
      // flowVisible: false,
      flowActiveId: null,
      flowChartData: [],

      colorList: [
        {
          label: "流速",
          type: "us_vel",
          list: [
            {
              color: "#f00",
              value: "<5cm/s",
            },
            {
              color: "#080",
              value: "<10cm/s",
            },
            {
              color: "#87CEFA",
              value: "<20cm/s",
            },
            {
              color: "#00f",
              value: ">=20cm/s",
            },
          ],
        },
        {
          label: "总磷",
          type: "hydro_rr_tph",
          list: [
            {
              color: "#0033FF",
              value: "I类",
            },
            {
              color: "#0066FF",
              value: "II类",
            },
            {
              color: "#3399FF",
              value: "III类",
            },
            {
              color: "#66CCFF",
              value: "IV类",
            },
            {
              color: "#F08080",
              value: "V类",
            },
            {
              color: "#f00",
              value: "劣V类",
            },
          ],
        },
        {
          label: "氨氮",
          type: "hydro_rr_nh4",
          list: [
            {
              color: "#0033FF",
              value: "I类",
            },
            {
              color: "#0066FF",
              value: "II类",
            },
            {
              color: "#3399FF",
              value: "III类",
            },
            {
              color: "#66CCFF",
              value: "IV类",
            },
            {
              color: "#F08080",
              value: "V类",
            },
            {
              color: "#f00",
              value: "劣V类",
            },
          ],
        },
        {
          label: "化学需氧量",
          type: "hydro_rr_cod",
          list: [
            {
              color: "#0033FF",
              value: "I类",
            },
            {
              color: "#0066FF",
              value: "II类",
            },
            {
              color: "#3399FF",
              value: "III类",
            },
            {
              color: "#66CCFF",
              value: "IV类",
            },
            {
              color: "#F08080",
              value: "V类",
            },
            {
              color: "#f00",
              value: "劣V类",
            },
          ],
        },
      ],

      dateList: [],
      activeDateIndex: null,
      activeTimeIndex: null,

      isPlay: false,

      timeList: [],
    };
  },

  computed: {
    activeDate() {
      if (this.dateList.length > 0) {
        return this.dateList[this.activeDateIndex].date;
      } else {
        return null;
      }
    },
    activeTime() {
      if (this.dateList.length > 0) {
        return this.dateList[this.activeDateIndex].timeList[
          this.activeTimeIndex
        ];
      } else {
        return null;
      }
    },

    ...mapState("map", [
      "activeRehearsalWaterId",
      "rehearsalWaterVisible",
      "flowId",
      "flowVisible",
    ]),
  },

  watch: {
    selectedSubType(newValue, oldValue) {
      if (this.isWaterQuality(newValue)) {
        this.selectedType = null;
        this.setClearMap();
      }

      if (this.isWaterQuality(oldValue)) {
        this.setClearMap();
      } else {
        if (oldValue === "hydro_depnod") {
          console.log(
            "清空地图水位 marker，交给了layout map自动清空其余 marker"
          );
        } else {
          console.log(
            "清空地图流量 labelMarker，交给了layout map自动清空其余 marker"
          );
        }
      }
      if (newValue) {
        this.getList(newValue);
      }
    },

    selectedType(newValue) {
      if (newValue) {
        if (
          this.selectedSubType === "hydro_rr_tph" ||
          this.selectedSubType === "hydro_rr_nh4" ||
          this.selectedSubType === "hydro_rr_cod"
        ) {
          this.selectedSubType = null;
          this.setClearMap();
        }
        this.getList(newValue);
      }
    },

    // click marker
    activeId(newValue, oldValue) {
      if (newValue !== oldValue) {
        fetchDetail({
          type: "hydro_depnod",
          ids: [this.activeId],
          beginTs: this.dateList[0].date + " " + this.dateList[0].timeList[0],
          endTs:
            this.dateList[this.dateList.length - 1].date +
            " " +
            this.dateList[this.dateList.length - 1].timeList[0],
        }).then((res) => {
          if (Array.isArray(res) && res.length > 0) {
            this.visible = true;
            this.chartData = res;
          } else {
            console.log("没有数据");
          }
        });
      }
    },

    // 流量
    flowActiveId(newValue, oldValue) {
      if (newValue !== oldValue) {
        fetchDetail({
          type: "hydro_ds_flow",
          ids: [this.flowActiveId],
          beginTs: this.dateList[0].date + " " + this.dateList[0].timeList[0],
          endTs:
            this.dateList[this.dateList.length - 1].date +
            " " +
            this.dateList[this.dateList.length - 1].timeList[0],
        }).then((res) => {
          if (Array.isArray(res) && res.length > 0) {
            console.log("res", res);
            this.flowVisible = true;
            this.flowChartData = Object.freeze(res);
          } else {
            console.log("没有数据");
          }
        });
      }
    },

    // 用户切换日期，改变时间选中
    activeDateIndex() {
      this.activeTimeIndex = 0;
    },
    activeTimeIndex() {
      this.debouncedRefreshData();
    },
  },

  mounted() {
    this.setClearMap();

    this.debouncedRefreshData = debounce(this.refreshData, 500);

    fetchCategory().then((res) => {
      console.log("category", res);
      if (Array.isArray(res)) {
        this.categoryList = res;
        this.categorySelectList = res
          .map((item) => item.duration + "-" + item.condition)
          .filter((item, index, self) => self.indexOf(item) === index);
        if (res.length > 0) {
          this.selectedCategory = this.categorySelectList[0];

          fetchTimeList().then((res) => {
            if (Array.isArray(res)) {
              const dateList = [];
              res.forEach((item) => {
                if (typeof item === "string") {
                  const arr = item.split(" ");
                  if (arr.length === 2) {
                    const index = dateList.findIndex(
                      (dateObj) => dateObj.date === arr[0]
                    );
                    if (index > -1) {
                      dateList[index].timeList.push(arr[1]);
                    } else {
                      dateList.push({
                        date: arr[0],
                        timeList: [arr[1]],
                      });
                    }
                  }
                }
              });
              if (dateList.length > 0) {
                this.dateList = dateList;
                this.activeDateIndex = 0;
              }
            }
          });
        }
      }
    });
  },

  methods: {
    ...mapActions("map", ["getWaterGateList", "getRehearsalList"]),
    ...mapMutations("map", [
      "setClearMap",
      "setRehearsalWaterVisible",
      "setFlowVisible",
    ]),

    isWaterQuality(type) {
      return (
        type === "hydro_rr_tph" ||
        type === "hydro_rr_nh4" ||
        type === "hydro_rr_cod"
      );
    },

    // 切换日期和时间刷新数据
    refreshData() {
      if (this.activeDate && this.activeTime) {
        if (this.selectedSubType) {
          // 默认加载当前选中的
          this.getList(this.selectedSubType);
        }
        if (this.selectedType) {
          this.getList(this.selectedType);
        }

        setTimeout(() => {
          this.getWaterGateList();
        }, 2000);
      }
    },

    play() {
      this.isPlay = !this.isPlay;

      if (this.isPlay) {
        this.timer = setInterval(() => {
          if (this.dateList.length > this.activeDateIndex) {
            const timeListLength =
              this.dateList[this.activeDateIndex].timeList.length;
            if (timeListLength > this.activeTimeIndex) {
              if (this.activeTimeIndex === timeListLength - 1) {
                // 最后一个时间节点
                this.activeTimeIndex = 0;
                if (this.activeDateIndex === this.dateList.length - 1) {
                  this.activeDateIndex = 0;
                } else {
                  this.activeDateIndex++;
                }
              } else {
                this.activeTimeIndex++;
              }
            }
          }
        }, 1000);
        this.$once("hook:beforeDestroy", () => {
          if (this.timer) {
            clearInterval(this.timer);
          }
        });
      } else {
        if (this.timer) {
          clearInterval(this.timer);
        }
      }
    },

    setActive(item, element) {
      this.activeDate = item;
      this.activeTime = element;
      if (this.selectedType) {
        this.getList(this.selectedType);
      }
      this.getList(this.selectedSubType);
    },

    // 关闭弹窗清空状态
    cancel() {
      this.visible = false;
      this.flowVisible = false;
      this.chartData = [];
      this.flowChartData = [];
    },

    getList(type) {
      const item = this.categoryList.find(
        (item) =>
          this.selectedCategory === item.duration + "-" + item.condition &&
          item.type === type
      );

      this.getRehearsalList({
        ts: this.activeDate + " " + this.activeTime,
        type,
        pid: item ? item.id : "",
      });
    },
  },
};
</script>
<style lang='less' scoped>
.panel {
  position: fixed;
  top: 12px;
  left: 12px;
  bottom: 64px;
  width: 280px;
  z-index: 99;

  display: flex;
  flex-direction: column;

  .header {
    background-color: #67927f;
    color: #fff;
    padding: 8px 12px;
    font-weight: bold;
  }

  .body {
    flex: 1;
    display: flex;
    flex-direction: column;

    .label {
      // margin-right: 12px;
      background-color: #fff;
      padding-left: 12px;
      font-weight: bold;
      color: #67927f;
    }

    .field {
      background-color: #fff;
      padding: 8px 12px;
    }
  }
}

.tool {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  padding: 12px;
  background-color: #fff;
  display: flex;
  align-items: center;
}

.icon {
  color: #67927f;
  font-size: 28px;
  font-weight: bolder;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.control {
  position: fixed;
  top: 10vh;
  right: 1.5vw;
  z-index: 99;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.scale {
  background-color: #fff;
  margin-top: auto;
  display: grid;
  font-size: 12px;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  padding: 12px;

  .item {
    display: flex;
    align-items: center;
  }
  .color {
    height: 16px;
    width: 8px;
    margin-right: 8px;
  }
}
</style>