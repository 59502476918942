<template>
  <div class="title"><slot /></div>
</template>

<script>
export default {};
</script>
<style lang="less" scoped>
.title {
  background-image: url("../assets/title-bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 1.6667rem;
  line-height: 1.6667rem;
  font-size: 0.9375rem;
  display: flex;
  align-items: center;
  padding-left: 3.3333rem;
  margin-bottom: 0.5208rem;
}
</style>